/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { FC, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'
import { useRecommendedProductsActions, useAppSelector } from '../hooks'
import { TemplateActionNames } from '../consts/defaultConfigValues/defaultConstants'
import {
  useClearCart,
  useDeleteProductFromCart,
  usePlaceOrderFlow,
} from '../hooks/cart'
import { ITemplate, ITemplateActions } from '../interfaces/templates'
import Default from './Default'
import { IGenericFunction } from '../interfaces'
import getAnalyticsItems from '../utils/getAnalyticsItems'
import { getAnalyticsCartPayload } from '../utils/getAnalyticsPayload'

const Cart: FC<ITemplate> = ({ ...props }) => {
  const { config } = useAppSelector((state) => state.defaultSlice)
  const cart = useAppSelector((state) => state.cartSlice)

  useEffect(() => {
    const { pathname } = window.location
    const route = pathname.split('/').pop()
    if (route === 'cart') {
      const cartAnalyticsItems = getAnalyticsItems(cart)
      const analyticsPayload = getAnalyticsCartPayload(
        cart,
        cartAnalyticsItems,
        config,
      )
      ReactGA.event('view_cart', analyticsPayload)
      window.fbq('trackCustom', 'ViewCart', {
        ...analyticsPayload,
        currency: config?.options?.currencyPrefix,
      })
    }
  }, [])
  return <Default {...props} />
}

export default Cart
