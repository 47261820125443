import { useNavigate } from 'react-router-dom'
import { ICoditionalPushViewProps, IPushViewProps } from '../interfaces/actions'

export const usePushView = () => {
  const navigate = useNavigate()
  const pushView = ({ path, state }: IPushViewProps) => {
    navigate(path, state)
  }

  return pushView
}

export const useConditionalPushView = () => {
  const navigate = useNavigate()
  const pushView = ({ path, state, values }: ICoditionalPushViewProps) => {
    const allConditionsAreTrue = values.reduce(
      (accumulator, { value1, value2 }) => accumulator && value1 === value2,
      true,
    )
    if (allConditionsAreTrue) {
      navigate(path, state)
    }
  }

  return pushView
}
export default usePushView
