import { useNavigate } from 'react-router-dom'
import { IGoBackProps } from '../interfaces/actions'
import useAppSelector from './useAppSelector'

export const useBack = () => {
  const navigate = useNavigate()
  const goBack = ({ path, state }: IGoBackProps) => {
    if (path) {
      navigate(path, state)
      return
    }
    navigate(-1)
  }
  return goBack
}

export const useDynamicBack = () => {
  const minHistoryLength = 2
  const navigate = useNavigate()
  const storefrontName = useAppSelector((state) => state.defaultSlice.storeName)
  const sessionID = useAppSelector((state) => state.defaultSlice.sessionId)
  const homePath = `/${storefrontName}/${sessionID}/`
  const goBack = ({ path, state }: IGoBackProps) => {
    if (path) {
      navigate(path, state)
      return
    }
    if (window.history.length > minHistoryLength) {
      navigate(-1)
      return
    }
    navigate(homePath)
  }

  return goBack
}

export default useBack
