import useAppDispatch from './useAppDispatch'
import useDialog from './useDialog'
import { setValidators } from '../redux/slices/config'
import {
  TextLibrary,
  CinnamonActionNames,
  TemplateActionNames,
  CinnamonVariables,
} from '../consts/defaultConfigValues/defaultConstants'
import getValidatorDialogsProps from '../utils/getValidatorDialogsProps'

const confirmOrderDialog = {
  id: 'confirmOrderdialog-0003',
  icon: {
    name: '"MdCheck"',
  },
  title: {
    value: TextLibrary.CART_CONFIRM_TITLE,
  },
  message: {
    value: TextLibrary.CART_CONFIRM_MESSAGE,
  },
  buttons: [
    {
      label: TextLibrary.ACTIONS_YES,
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.CREATE_ORDER,
            args: {
              sessionId: CinnamonVariables.SESSION_ID,
              storeFrontName: CinnamonVariables.STOREFRONT_NAME,
              phone: CinnamonVariables.WORKFLOW_ID,
              cart: CinnamonVariables.CART,
              session: CinnamonVariables.SESSION_DATA,
            },
          },
          {
            name: 'closeDialog',
          },
          {
            name: TemplateActionNames.PLACE_ORDER_FLOW_NEXT,
          },
        ],
      },
    },
    {
      label: TextLibrary.ACTIONS_CANCEL,
      color: 'inherit',
      variant: 'outlined',
      actions: {
        cinnamonActions: [
          {
            name: 'closeDialog',
          },
          {
            name: TemplateActionNames.PLACE_ORDER_CLEAR,
          },
        ],
      },
    },
  ],
}

/*
  reducer: defaultSlice
  key: validations: Array<string>
  this manage to open all the dialogs for validations as a recursive way while the are pending validations, those are set on usePlaceOrder hook
*/

export const useLoadDialogValidations = () => {
  const dispatch = useAppDispatch()
  const { openDialog } = useDialog()
  const loadDialogs = ({ validators }: { validators: string[] }) => {
    const values = [...validators]
    const currentValidator = values.pop()
    if (currentValidator) {
      dispatch(setValidators(values))
      const validatorProps = getValidatorDialogsProps(currentValidator)
      openDialog({ ...validatorProps })
    } else {
      // this happen when all the validations where accepted, in case of reject one of the validations the process is reeestarted.
      openDialog({ ...confirmOrderDialog })
    }
  }

  return loadDialogs
}

export default useLoadDialogValidations
