import useAppDispatch from './useAppDispatch'
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from '../redux/slices/config'
import { IModalActionsProps } from '../interfaces/actions'
import { DefaultModalContainerNames } from '../consts/defaultConfigValues/defaultConstants'
import useBack from './useBack'
import useSetSubitems from './catalog/useSetSubitems'

interface IPerformActionModal extends Pick<IModalActionsProps, 'container'> {
  modalStatus?: boolean
}

export const useModal = () => {
  const dispatch = useAppDispatch()
  const goBack = useBack()
  const { clearSubitems } = useSetSubitems()

  const performModalActions = ({
    container,
    modalStatus,
  }: IPerformActionModal): void => {
    if (
      container === DefaultModalContainerNames.PRODUCT_DETAIL &&
      modalStatus
    ) {
      goBack({})
      clearSubitems()
    }
  }

  const openModal = ({ container, state, item = '' }: IModalActionsProps) => {
    dispatch(openModalAction({ container, state, item }))
  }

  const closeModal = ({ container, state }: IModalActionsProps) => {
    dispatch(closeModalAction({ container, state }))
  }

  return { openModal, closeModal, performModalActions }
}

export default useModal
