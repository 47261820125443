/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { FC, useMemo, useState } from 'react'
import { AppContainer } from '@engyalo/delivery-ui-components'
import { selectTreatmentValue } from '@splitsoftware/splitio-redux'
import ModalContainer from '../containers/ModalContainer'
import { useAppSelector } from '../hooks'
import Dialog from '../components/Dialog'
import { IComponentPromise, ITemplateDefault } from '../interfaces/templates'
import { useLoadViews } from '../hooks/templates/useLoadViews'
import useTemplateComponentsState from '../hooks/templates/useTemplateComponentsState'
import { useModalsList } from '../hooks/templates/useModalsList'
import Alert from '../components/Alert'
import { SPLIT_IO_FEATURES } from '../consts/splitio'
import EventEmitter from '../classes/EventEmiter'
import EventManager from '../containers/EventManager'

const Default: FC<ITemplateDefault> = ({
  container: {
    uid: containerUid,
    header = {},
    body = {},
    footer = {},
    modals: modalsNames = [],
  },
  modals = {},
}) => {
  const eventEmitter = useMemo(() => new EventEmitter(), [])
  const state = useAppSelector((stateRedux) => stateRedux)

  const [isScriptInjected, setIsScriptInjected] = useState(false)
  const scriptUrl =
    'https://cdn.transcend.io/cm/8c5cabbe-7657-4cbd-9da0-f543ddbc88a8/airgap.js'
  const {
    defaultSlice: { modals: modalStatus, excludedUiComponents },
    splitReducer: splitio,
  } = state

  const userConsentTreatment = selectTreatmentValue(
    splitio,
    SPLIT_IO_FEATURES.ENABLE_USER_CONSENT,
  )

  const isScriptAlreadyInjected = () =>
    document.querySelectorAll(`[src="${scriptUrl}"]`).length > 0

  const addConcentScript = () => {
    if (!isScriptAlreadyInjected()) {
      const script = document.createElement('script')
      script.setAttribute('data-cfasync', 'false')
      script.src = scriptUrl
      document.head.appendChild(script)
      setIsScriptInjected(true)
    }
  }

  if (userConsentTreatment === 'on' && !isScriptInjected) {
    addConcentScript()
  }

  const modalsList = useModalsList(modalsNames, modals)

  const { props: headerProps, components: headerComponents } = header
  const { props: bodyProps, components: bodyComponents } = body
  const { props: footerProps, components: footerComponents } = footer

  const {
    headerComponentsList,
    setHeaderComponentsList,
    bodyComponentsList,
    setBodyComponentsList,
    footerComponentsList,
    setFooterComponentsList,
  } = useTemplateComponentsState()

  useLoadViews({
    headerComponents,
    setHeaderComponentsList,
    bodyComponents,
    setBodyComponentsList,
    footerComponents,
    excludedUiComponents,
    setFooterComponentsList,
    containerUid,
  })

  const renderComponentWrapper = ({
    wrapper: Wrapper,
    uid,
  }: IComponentPromise) => (
    <Wrapper
      state={state}
      eventEmitter={eventEmitter}
      parentUid={containerUid}
      key={uid}
    />
  )
  return (
    <>
      <EventManager eventEmitter={eventEmitter} />
      <AppContainer
        header={{
          ...headerProps,
          id: `${containerUid}-header`,
          components: headerComponentsList.map(renderComponentWrapper),
        }}
        body={{
          ...bodyProps,
          id: `${containerUid}-body`,
          components: bodyComponentsList.map(renderComponentWrapper),
        }}
        footer={{
          ...footerProps,
          id: `${containerUid}-footer`,
          components: footerComponentsList.map(renderComponentWrapper),
        }}
      />
      {modalStatus &&
        modalsList.map(({ name, data }) => (
          <ModalContainer
            modalStatus={modalStatus}
            key={data.uid}
            name={name}
            modal={data}
            eventEmitter={eventEmitter}
            state={state}
          />
        ))}
      <Alert />
      <Dialog eventEmitter={eventEmitter} state={state} />
    </>
  )
}

export default Default
