type CinnamonFunction = (...args: any[]) => unknown

class EventEmitter {
  private events: { [key: string]: CinnamonFunction[] }
  constructor() {
    this.events = {}
  }

  on(event: string, listener: CinnamonFunction) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(listener)
  }

  offAll() {
    this.events = {}
  }

  emit(event: string, args: any) {
    const listeners = this.events[event]
    if (listeners) {
      listeners.forEach((listener) => listener(args))
    }
  }
}

export default EventEmitter
