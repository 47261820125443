import React, { useEffect, useRef } from 'react'
import EventEmitter from '../classes/EventEmiter'
import {
  TemplateActionNames,
  CinnamonActionNames,
} from '../consts/defaultConfigValues/defaultConstants'
import {
  useModal,
  useDialog,
  useSearch,
  useProductDetail,
  useBundlesHandler,
  useSetCurrentBundleCount,
  useSetSubitems,
  useChosenFreeGoods,
  useAddProductFromPromotion,
  useCategoryFilters,
  useCloseGlobalPromotion,
  usePushView,
  useConditionalPushView,
  useBack,
  useDynamicBack,
  useBannersClick,
  useProductsLazyLoader,
  usePrefillCart,
  useSuggestionItems,
  useGetProductsNav,
  useCategoriesNavigation,
  useAddItemToCart,
  useDeleteItemFromCart,
  useLogAction,
  useCreateOrderHook,
  useLoadDialogValidations,
  useBackInStock,
  useClearCart,
  useDeleteProductFromCart,
  usePlaceOrderFlow,
  useRecommendedProductsActions,
  useAdjustProductQuantity,
  useValidateLinkedProducts,
  useClearFilters,
  useFetchFilterResults,
  useSetFilterCategory,
  useSetFilters,
  useAppSelector,
} from '../hooks'

interface EventManagerProps {
  eventEmitter: EventEmitter
  children?: React.ReactNode
}

const EventManager: React.FC<EventManagerProps> = ({
  eventEmitter,
  children,
}) => {
  const mainContainer = useRef<HTMLDivElement>(null)
  const state = useAppSelector((stateRedux) => stateRedux)
  // cinnamon actions
  const goBack = useBack()
  const goBackDynamic = useDynamicBack()
  const pushView = usePushView()
  const conditionalPushView = useConditionalPushView()
  const { search, clearSearch } = useSearch()
  const productDetail = useProductDetail()
  const { openModal, closeModal, performModalActions } = useModal()
  const { openDialog, closeDialog } = useDialog()
  const { addItemAction: addItemToCart } = useAddItemToCart()
  const { removeItemAction: deleteItemFromCart } = useDeleteItemFromCart()
  const logAction = useLogAction()
  const createOrder = useCreateOrderHook()
  const loadDialogsValidations = useLoadDialogValidations()
  const bundlesHandler = useBundlesHandler()
  const setCurrentBundleCount = useSetCurrentBundleCount()
  const { chosenFreegoods, confirmChosenFreegoods } = useChosenFreeGoods()
  const backInStockNotification = useBackInStock()
  const { setSubitems, clearSubitems } = useSetSubitems()
  const { adjustProductQuantity } = useAdjustProductQuantity()
  // catalog actions
  const { updateCategory, scrollToCategory, updateCurrentCategoryCatalog } =
    useCategoryFilters()
  const fetchSuggestedItems = useSuggestionItems()
  const { updatePrefillCartItem, addPrefillCartToCart } = usePrefillCart()
  const closeGlobalPromotions = useCloseGlobalPromotion()
  const getProductsNav = useGetProductsNav()
  const getProducts = useProductsLazyLoader()
  const bannersClick = useBannersClick()
  // cart actions
  const clearCartAction = useClearCart()
  const deleteProduct = useDeleteProductFromCart()
  const { closeRecommendedProducts, updateRecommendedButton } =
    useRecommendedProductsActions()
  const { placeOrderFlow, placeOrderFlowNext, placeOrderFlowClear } =
    usePlaceOrderFlow()
  const { validateLinkedProducts } = useValidateLinkedProducts()
  // categories actions
  const clearFilters = useClearFilters()
  const { setCategoriesNavigation, navigateToCategory } =
    useCategoriesNavigation()
  // filters actions
  const { getFilterResults } = useFetchFilterResults()
  const setFilterCategory = useSetFilterCategory()
  const setFilters = useSetFilters()
  // product detail actions
  const { addProductFromPromotion } = useAddProductFromPromotion()

  useEffect(() => {
    // cinnamon actions
    eventEmitter.on(CinnamonActionNames.CLOSE_MODAL, closeModal)
    eventEmitter.on(
      CinnamonActionNames.PERFORM_MODAL_ACTIONS,
      performModalActions,
    )
    eventEmitter.on(CinnamonActionNames.OPEN_MODAL, openModal)
    eventEmitter.on(CinnamonActionNames.OPEN_DIALOG, openDialog)
    eventEmitter.on(CinnamonActionNames.CLOSE_DIALOG, closeDialog)
    eventEmitter.on(CinnamonActionNames.PUSH_VIEW, pushView)
    eventEmitter.on(
      CinnamonActionNames.CONDITIONAL_PUSH_VIEW,
      conditionalPushView,
    )
    eventEmitter.on(CinnamonActionNames.CLEAR_SEARCH, clearSearch)
    eventEmitter.on(
      CinnamonActionNames.REMOVE_ITEM_FROM_CART,
      deleteItemFromCart,
    )
    eventEmitter.on(CinnamonActionNames.ADD_ITEM_TO_CART, addItemToCart)
    eventEmitter.on(CinnamonActionNames.GO_BACK, goBack)
    eventEmitter.on(CinnamonActionNames.GO_BACK_DYNAMIC, goBackDynamic)
    eventEmitter.on(CinnamonActionNames.SEARCH, search)
    eventEmitter.on(CinnamonActionNames.CREATE_ORDER, createOrder)
    eventEmitter.on(
      CinnamonActionNames.LOAD_DIALOGS_VALIDATIONS,
      loadDialogsValidations,
    )
    eventEmitter.on(CinnamonActionNames.LOG_ACTION, logAction)
    eventEmitter.on(CinnamonActionNames.GET_PRODUCT_DETAIL, productDetail)
    eventEmitter.on(CinnamonActionNames.BUNDLES_HANDLER, bundlesHandler)
    eventEmitter.on(CinnamonActionNames.SET_BUNDLE_COUNT, setCurrentBundleCount)
    eventEmitter.on(CinnamonActionNames.SET_SUBITEMS, setSubitems)
    eventEmitter.on(CinnamonActionNames.CLEAR_SUBITEMS, clearSubitems)
    eventEmitter.on(CinnamonActionNames.CHOSEN_FREEGOODS, chosenFreegoods)
    eventEmitter.on(
      CinnamonActionNames.CONFIRM_CHOSEN_FREEGOODS,
      confirmChosenFreegoods,
    )
    eventEmitter.on(
      CinnamonActionNames.NOTIFY_BACK_IN_STOCK,
      backInStockNotification,
    )
    eventEmitter.on(
      CinnamonActionNames.UPDATE_ITEMS_INPUT,
      adjustProductQuantity,
    )
    // catalog actions
    eventEmitter.on(
      TemplateActionNames.CATALOG_SCROLL_TO_CATEGORY,
      scrollToCategory,
    )
    eventEmitter.on(TemplateActionNames.CATALOG_UPDATE_CATEGORY, updateCategory)
    eventEmitter.on(
      TemplateActionNames.CATALOG_UPDATE_CATEGORY_CATALOG,
      updateCurrentCategoryCatalog,
    )
    eventEmitter.on(TemplateActionNames.CATALOG_BANNERS_CLICK, bannersClick)
    eventEmitter.on(
      TemplateActionNames.SUGGESTED_ITEMS_FETCH_PRODUCTS,
      fetchSuggestedItems,
    )
    eventEmitter.on(
      TemplateActionNames.PREFILL_CART_UPDATE_ITEM,
      updatePrefillCartItem,
    )
    eventEmitter.on(
      TemplateActionNames.PREFILL_CART_TO_CART,
      addPrefillCartToCart,
    )
    eventEmitter.on(
      TemplateActionNames.CLOSE_GLOBAL_PROMOTIONS_ALERT,
      closeGlobalPromotions,
    )
    eventEmitter.on(
      TemplateActionNames.CATEGORIES_NAVIGATION_GET_PRODUCTS,
      getProductsNav,
    )
    eventEmitter.on(
      TemplateActionNames.CATEGORIES_LAZY_GET_PRODUCTS,
      getProducts,
    )

    // cart actions
    eventEmitter.on(TemplateActionNames.CART_CLEAR_CART, clearCartAction)
    eventEmitter.on(TemplateActionNames.CART_DELETE_PRODUCT, deleteProduct)
    eventEmitter.on(
      TemplateActionNames.CART_CLOSE_RECOMMENDED_PRODUCTS_MODAL,
      closeRecommendedProducts,
    )
    eventEmitter.on(
      TemplateActionNames.CART_UPDATE_RECOMMENDED_PRODUCT_BUTTON,
      updateRecommendedButton,
    )
    eventEmitter.on(TemplateActionNames.PLACE_ORDER_FLOW, placeOrderFlow)
    eventEmitter.on(
      TemplateActionNames.PLACE_ORDER_FLOW_NEXT,
      placeOrderFlowNext,
    )
    eventEmitter.on(TemplateActionNames.PLACE_ORDER_CLEAR, placeOrderFlowClear)
    eventEmitter.on(
      TemplateActionNames.LINKED_PRODUCTS_VALIDATE_STOCK,
      validateLinkedProducts,
    )
    // categories actions
    eventEmitter.on(TemplateActionNames.FILTERS_CLEAR_FILTERS, clearFilters)
    eventEmitter.on(
      TemplateActionNames.CATEGORIES_NAVIGATION_SET_CATEGORIES,
      setCategoriesNavigation,
    )
    eventEmitter.on(
      TemplateActionNames.CATEGORIES_NAVIGATION_NAVIGATE_TO_CATEGORY,
      navigateToCategory,
    )
    // filters actions
    eventEmitter.on(
      TemplateActionNames.FILTERS_GET_FILTERS_RESULTS,
      getFilterResults,
    )
    eventEmitter.on(TemplateActionNames.FILTERS_SET_FILTERS, setFilters)
    eventEmitter.on(
      TemplateActionNames.FILTERS_SET_FILTER_CATEGORY,
      setFilterCategory,
    )
    // product detail actions
    eventEmitter.on(
      TemplateActionNames.PRODUCT_DETAIL_ADD_FROM_PROMOTION,
      addProductFromPromotion,
    )

    return () => {
      eventEmitter.offAll()
    }
  }, [state])

  return (
    <div ref={mainContainer}>
      <div
        onClick={addPrefillCartToCart}
        id={TemplateActionNames.PREFILL_CART_TO_CART}
      />
      {children}
    </div>
  )
}

export default EventManager
