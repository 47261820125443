/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { FC, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { ITemplate } from '../interfaces/templates'
import Default from './Default'

const Categories: FC<ITemplate> = ({ ...props }) => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/${props.container.uid}` })
    window.fbq('track', 'PageView', { page: `/${props.container.uid}` })
  }, [])
  return <Default {...props} />
}

export default Categories
