import { PromotionType } from '../../graphQL/commons'
import { IPromotionUiCart, IPromotionUiCartInput } from '../../interfaces'
import { getActivePromotionsFields } from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'
import formatPercentage from '../formatPercentage'

export const getComboPromotionUiDetails = ({
  activePromotion,
  cartProduct,
  config,
  cartProducts,
}: IPromotionUiCartInput): IPromotionUiCart => {
  const texts = config?.texts?.cartPromotions
  const { discounts, promotionalQty, price, quantity } = cartProduct
  const {
    fields: {
      customerBuysQuantity,
      customerGetsPercentage,
      limitQuantity,
      customerBuysItems,
      customerGetsItems,
      customerGetsQuantity,
      type,
    },
    promotion,
    count,
  } = getActivePromotionsFields(activePromotion)
  const [productToBuy] = customerBuysItems || []
  const [productToGet] = customerGetsItems || []
  const promotionPercentageFormat = config?.options?.promotionPercentageFormat
  const isSameProduct =
    customerBuysItems?.length === 1 &&
    customerGetsItems?.length === 1 &&
    productToBuy === productToGet
  const discountIsFullProduct: boolean =
    isSameProduct || !customerGetsPercentage
  const discount = discountIsFullProduct
    ? String(customerGetsQuantity + customerBuysQuantity)
    : formatPercentage(customerGetsPercentage, promotionPercentageFormat)
  const {
    freeItemsLabel = '',
    comboDifferentProductLabel = '',
    comboDifferentProductPercentageLabel = '',
    defaultSaving = '',
    defaultLimitLabel = '',
    comboPromotionOrigin = '',
    comboPromotionOriginORSymbol = '',
  } = texts || {}

  const formatArgs: IFormatPromotionMessage = {
    message: '',
    promotion,
    config,
    saving: discounts,
    amount: discounts,
    placeholders: {
      discount,
      value: String(customerBuysQuantity),
      count: String(promotionalQty),
    },
  }
  let label: string
  const promoOrigin: string[] = []
  if (isSameProduct) {
    label = formatPromotionMessage({
      ...formatArgs,
      message: freeItemsLabel as string,
    })
  } else {
    let newPromotionalQty = promotionalQty
    if (
      (type === PromotionType.COMBO || type === PromotionType.COMPLEX) &&
      config?.options?.freegoodAsCartGroup &&
      cartProducts
    ) {
      newPromotionalQty = cartProducts.reduce(
        (acc, product) =>
          product.sku === cartProduct.sku ? product.promotionalQty + acc : acc,
        0,
      )
    }
    const defaultLabel = discountIsFullProduct
      ? (comboDifferentProductLabel as string)
      : (comboDifferentProductPercentageLabel as string)
    label = formatPromotionMessage({
      ...formatArgs,
      placeholders: {
        ...formatArgs.placeholders,
        count: String(discountIsFullProduct ? newPromotionalQty : count),
      },
      message: defaultLabel,
    })
  }
  // promo origin for combo complex
  if (
    type === PromotionType.COMPLEX &&
    cartProducts &&
    config?.options?.showFreegoodsComboOrigin &&
    customerBuysItems.length > 1
  ) {
    promoOrigin.push(
      formatPromotionMessage({
        ...formatArgs,
        cartProducts,
        message: comboPromotionOrigin as string,
      }),
    )
    return {
      label,
      promoOrigin,
    }
  }
  // promo origin for combo
  if (
    type === PromotionType.COMBO &&
    config?.options?.showFreegoodsComboOrigin &&
    cartProducts
  ) {
    promoOrigin.push(
      formatPromotionMessage({
        ...formatArgs,
        cartProducts,
        message: comboPromotionOrigin as string,
        promoOriginSymbol: comboPromotionOriginORSymbol,
      }),
    )
    return {
      label,
      promoOrigin,
    }
  }

  if (discounts) {
    const discountsNoCombo = discounts - promotionalQty * price
    const finalDiscount =
      quantity === promotionalQty
        ? discountsNoCombo
        : discountsNoCombo / (quantity - promotionalQty)
    const value = price - finalDiscount

    const result: IPromotionUiCart = {
      label,
      limit: limitQuantity
        ? formatPromotionMessage({
            ...formatArgs,
            limitQuantity,
            message: defaultLimitLabel as string,
          })
        : '',
      saving: formatPromotionMessage({
        ...formatArgs,
        message: defaultSaving as string,
      }),
      type,
      value,
    }
    return result
  }
  return {
    label,
    type,
  }
}
