import { PromotionType } from '../../graphQL/commons'
import {
  IConfig,
  IProduct,
  IPromotion,
  IPromotionDetails,
  IPromotionUiCatalog,
  IPromotionsDetails,
} from '../../interfaces'
import { getComboPromotionUiDetails } from './getComboPromotionUiDetails'
import { getComplexPromotionUiDetails } from './getComplexPromotionUiDetails'
import { getDirectPromotionUiDetails } from './getDirectPromotionUiDetails'
import { getVolumePromotionUiDetails } from './getVolumePromotionUiDetails'

export const getMultiPromotionTag = ({
  texts: { promotions: textsPromotions },
}: IConfig): IPromotionUiCatalog => {
  const {
    multipromoText: label = '',
    multipromoSecondaryText: secondaryText = '',
  } = textsPromotions
  return {
    showIcon: true,
    label,
    secondaryText,
    description: '',
  }
}
interface IGetMultiPromotionUiArgs {
  config: IConfig
  product: IProduct
}

type Actions = Record<
  Partial<PromotionType>,
  (
    promotion: IPromotion,
    product: IProduct,
    config: IConfig,
  ) => IPromotionUiCatalog | undefined | void
>
const actions: Actions = {
  [PromotionType.DIRECT]: (promotion, { price }, config) => {
    return getDirectPromotionUiDetails({
      price: price || 0,
      config,
      promotion,
    })
  },
  [PromotionType.VOLUME]: (promotion, product, config) => {
    return getVolumePromotionUiDetails({
      product,
      config,
      promotion,
    })
  },
  [PromotionType.COMBO]: (promotion, _product, config) => {
    return getComboPromotionUiDetails({
      promotion,
      config,
    })
  },
  [PromotionType.COMPLEX]: (promotion, product, config) => {
    return getComplexPromotionUiDetails({
      promotion,
      config,
    })
  },
  [PromotionType.TOTAL]: () => {},
}
const getPromotionsDetails = ({
  config,
  product,
}: IGetMultiPromotionUiArgs): IPromotionDetails[] => {
  const result: IPromotionDetails[] = []
  const { promotions = [] } = product
  promotions?.forEach((promotion) => {
    const { type } = promotion
    const action = actions[type]

    if (!action) {
      return
    }
    const promotionUiDetails = action(promotion, product, config)

    if (!promotionUiDetails) {
      return
    }
    const { description, saving } = promotionUiDetails
    result.push({
      buyText: description,
      discountText: '',
      savingText: saving,
    })
  })
  return result
}

export const getMultiPromotionUiDetails = ({
  config,
  product,
}: IGetMultiPromotionUiArgs): IPromotionsDetails => {
  const { label, secondaryText = '' } = getMultiPromotionTag(config)
  const details = getPromotionsDetails({
    config,
    product,
  })

  return {
    title: label,
    secondaryTitle: secondaryText,
    details,
  }
}
