import { PromotionType } from '../../graphQL/commons'
import {
  ICartProduct,
  IPromotionUiCart,
  IPromotionUiCartInput,
  ITransformCartProduct,
  TransformAction,
} from '../../interfaces'
import { getComboPromotionUiDetails } from './getComboPromotionUiDetails'
import { getComplexPromotionUiDetails } from './getComplexPromotionUiDetails'
import { getDirectPromotionUiDetails } from './getDirectPromotionUiDetails'
import { getVolumePromotionUiDetails } from './getVolumePromotionUiDetails'

export const promotionsMap: Record<
  PromotionType,
  (
    isTotalFreegods: boolean,
  ) => (args: IPromotionUiCartInput) => IPromotionUiCart | undefined
> = {
  [PromotionType.COMPLEX]: () => getComplexPromotionUiDetails,
  [PromotionType.COMBO]: () => getComboPromotionUiDetails,
  [PromotionType.DIRECT]: () => getDirectPromotionUiDetails,
  [PromotionType.VOLUME]: () => getVolumePromotionUiDetails,
  [PromotionType.TOTAL]: (isTotalFreegods) => (args: IPromotionUiCartInput) =>
    isTotalFreegods ? getComboPromotionUiDetails(args) : undefined,
}
const initialComboIndexName = 1

export const getPromotionDetailsByType: TransformAction<
  ITransformCartProduct,
  ICartProduct
> = ({
  activePromotionFields: {
    fields: { type, customerGetsQuantity },
  },
  cartProduct,
  activePromotion,
  config,
  cartProducts,
  activePromotions,
}) => {
  const promotion = promotionsMap[type](!!customerGetsQuantity)({
    activePromotion,
    cartProduct,
    config,
    cartProducts,
  })!
  let newCartProductData = {
    ...cartProduct,
  }
  const promoGroups = Object.keys(activePromotion.itemsConsumed || {})
  const texts = config?.texts?.cartPromotions
  const isComboGroup =
    Object.keys(activePromotion.itemsConsumed || {}).length > 1
  if (
    type === PromotionType.VOLUME &&
    promoGroups.length > 0 &&
    promoGroups.includes(newCartProductData.sku) &&
    isComboGroup
  ) {
    const comboPromos = activePromotions.filter(
      (promo) =>
        Object.keys(promo.itemsConsumed || {}).length > 1 &&
        promo.promotion.type === PromotionType.VOLUME,
    )
    const currentPromoIndex = comboPromos.findIndex(
      (promo) => promo.itemsConsumed === activePromotion.itemsConsumed,
    )
    const comboBaseNumber = currentPromoIndex + initialComboIndexName
    const comboGroupId = `${texts?.comboGroupPreffix || ''}${comboBaseNumber}`
    newCartProductData = {
      ...cartProduct,
      comboGroupId: comboGroupId,
    }
  }
  return {
    ...cartProduct,
    ...newCartProductData,
    promotion: {
      ...promotion,
      label: activePromotion.label || promotion.label,
    },
  }
}
