/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { FC, useMemo } from 'react'
import {
  Alert,
  AppContainer,
  Carousel,
  CatalogueList,
  HeaderList,
  MainFooter,
  MainHeader,
  TabsMenu,
} from '@engyalo/delivery-ui-components'
import { useAppSelector } from '../hooks'
import Dialog from '../components/Dialog'
import { ITemplate } from '../interfaces/templates'
import CinnamonAlert from '../components/Alert'
import { useModalsList } from '../hooks/templates/useModalsList'
import { getCinnamonPropsContainer } from '../hooks/useCinnamonProps'
import Dropdown from '@engyalo/delivery-ui-components/lib/ui/components/DropdownMenu/DropdownMenu'
import EventManager from '../containers/EventManager'
import EventEmitter from '../classes/EventEmiter'
import ModalContainer from '../containers/ModalContainer'

const getPropsUsingUid = (name: string, section?: any) => {
  const component = section?.components.find((c: any) => c.uid === name)
  return component?.props
}

const CatalogFixed: FC<ITemplate> = ({
  container: xContainer,
  modals = {},
}) => {
  const eventEmitter = useMemo(() => new EventEmitter(), [])
  const state = useAppSelector((stateRedux) => stateRedux)

  const container = useMemo(
    () => getCinnamonPropsContainer(xContainer, state, eventEmitter),
    [xContainer, state, eventEmitter],
  )

  const modalsList = useModalsList(container?.modals || [], modals)

  const mainHeaderProps = useMemo(
    () => getPropsUsingUid('cataloguedefault-header-main', container?.header),
    [container],
  )

  const carouselBannersProps = useMemo(
    () =>
      getPropsUsingUid('cataloguedefault-header-banners', container?.header),
    [container],
  )
  const categoryNavProps = useMemo(
    () => getPropsUsingUid('cataloguedefault-header-nav', container?.header),
    [container],
  )

  const categoryDropdownProps = useMemo(
    () => getPropsUsingUid('catalogue-header-dropdown', container?.header),
    [container],
  )
  const globalPromotionAlertProps = useMemo(
    () => getPropsUsingUid('GlobalPromotionAlert', container?.header),
    [container],
  )
  const creditLimitReachedProps = useMemo(
    () => getPropsUsingUid('comp-credit-limit-reached', container?.header),
    [container],
  )
  //body
  const carouselHeaderProps = useMemo(
    () => getPropsUsingUid('recomendations-header-carousel', container?.body),
    [container],
  )
  const recomendationsCarouselProps = useMemo(
    () => getPropsUsingUid('recomendations-body-carousel', container?.body),
    [container],
  )
  const headerListProps = useMemo(
    () => getPropsUsingUid('header-body-list', container?.body),
    [container],
  )

  const catalogueProps = useMemo(
    () => getPropsUsingUid('cataloguedefault-body-list', container?.body),
    [container],
  )

  // footer
  const mainFooterProps = useMemo(
    () => getPropsUsingUid('cataloguedefault-footer-main', container?.footer),
    [container],
  )

  return (
    <>
      <EventManager eventEmitter={eventEmitter} />
      <AppContainer
        header={{
          ...container?.header?.props,
          id: `${container?.uid}-header`,
          components: [
            mainHeaderProps ? <MainHeader {...mainHeaderProps} /> : null,
            carouselBannersProps ? (
              <Carousel {...carouselBannersProps} />
            ) : null,
            categoryNavProps ? <TabsMenu {...categoryNavProps} /> : null,
            categoryDropdownProps ? (
              <Dropdown {...categoryDropdownProps} />
            ) : null,
            globalPromotionAlertProps ? (
              <Alert {...globalPromotionAlertProps} />
            ) : null,
            creditLimitReachedProps ? (
              <Alert {...creditLimitReachedProps} />
            ) : null,
          ],
        }}
        body={{
          ...container?.body?.props,
          id: `${container?.uid}-body`,
          components: [
            carouselHeaderProps ? (
              <HeaderList {...carouselHeaderProps} />
            ) : null,
            recomendationsCarouselProps ? (
              <Carousel {...recomendationsCarouselProps} />
            ) : null,
            headerListProps ? <HeaderList {...headerListProps} /> : null,
            catalogueProps ? <CatalogueList {...catalogueProps} /> : null,
          ],
        }}
        footer={{
          ...container?.footer?.props,
          id: `${container?.uid}-footer`,
          components: [
            mainFooterProps ? <MainFooter {...mainFooterProps} /> : null,
          ],
        }}
      />
      {state.defaultSlice.modals &&
        modalsList.map(({ name, data }) => (
          <ModalContainer
            modalStatus={state.defaultSlice.modals || {}}
            key={data.uid}
            name={name}
            modal={data}
            eventEmitter={eventEmitter}
            state={state}
          />
        ))}
      <CinnamonAlert />
      <Dialog eventEmitter={eventEmitter} state={state} />
    </>
  )
}

export default CatalogFixed
