import { SearchBarProps } from '@engyalo/delivery-ui-components/lib/ui/components/SearchBar'
import { DefaultProps } from '../../../interfaces'
import {
  CinnamonActionNames,
  TextLibrary,
  DefaultModalContainerNames,
} from '../defaultConstants'

export const SearchBarDefault: DefaultProps<SearchBarProps> = {
  defaultValue: '{{catalog.search.term}}',
  placeholder: TextLibrary.SEARCH_PLACEHOLDER,
  autoFocus: true,
  searchTermMinLength: '{{globals.searchBar.searchTermMinLength}}' as any,
  searchTimeout: '{{globals.searchBar.searchTimeout}}' as any,
  searchTermsExceptions: '{{globals.searchBar.searchTermsExceptions}}' as any,
  searchActions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.SEARCH,
        args: {
          searchTerm: '{{this.searchTerm}}',
        },
      },
    ],
  } as any,
  backButton: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.CLOSE_MODAL,
          args: {
            container: DefaultModalContainerNames.SEARCH_PRODUCT,
          },
        },
      ],
    },
  } as any,
  clearButton: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.CLEAR_SEARCH,
        },
      ],
    },
  } as any,
}
