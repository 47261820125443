import useAppDispatch from '../useAppDispatch'
import {
  setCurrentBundleCount,
  setCurrentBundlePriceValue,
} from '../../redux/slices/catalog'
import useAppSelector from '../useAppSelector'

export const useSetCurrentBundleCount = () => {
  const productSelected = useAppSelector(
    (state) => state.catalogSlice.selectedProduct,
  )

  const currentBundleSubtotal = useAppSelector(
    (state) => state.catalogSlice.currentBundleSubtotal,
  )
  const dispatch = useAppDispatch()
  const setBundleCount = ({ newValue }: { newValue: number }) => {
    const selectedProductPrice = productSelected?.price || currentBundleSubtotal

    dispatch(setCurrentBundleCount(newValue))
    dispatch(setCurrentBundlePriceValue(selectedProductPrice * newValue))
  }
  return setBundleCount
}

export default useSetCurrentBundleCount
